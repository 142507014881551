import stockUtils from '../schemas/stock';
const STOCK_PROMOTION_TYPES_AGRUPATION = ['agrupation'];

export function concatPromos(allPromos) {
  const { stockPromos, variantPromos, currentColorPromos, skuPromoBadge  } = allPromos;
  const promos = [variantPromos, currentColorPromos, skuPromoBadge].flatMap(promoArray => promoArray || []).filter(Boolean);
  const result = [...promos];

  if(stockPromos){
    stockPromos.forEach(stockPromo => {
      const existingIndex = result.findIndex(existingPromo => existingPromo.id === stockPromo.id);

      if (existingIndex !== -1) {
        result[existingIndex] = stockPromo;
      } else {
        result.push(stockPromo);
      }
    });
  }

  return result.sort((firstPromo, secondPromo) => firstPromo.priority - secondPromo.priority);
};

export function colorHasPromoRange(variants) {
  const hasBadgeRange = colorHasBadgeRange(variants);
  const hasGifRange = colorHasGifRange(variants);

  return hasBadgeRange || hasGifRange;
};

export function comparePromotions(firstPromo, secondPromo) {
  if (firstPromo.isSPO && secondPromo.isSPO) {
    return firstPromo.id === secondPromo.id && firstPromo.colorVariantId === secondPromo.colorVariantId;
  }

  return firstPromo.id === secondPromo.id;
};

export function isValidBadgeTextSPO(badgeText) {
  return badgeText.isSPO && Object.keys(badgeText || {}).length;
};

export function isValidGifSPO(gif) {
  return gif.isSPO && Object.keys(gif || {}).length;
};

/* Merge promos spo in to stock response */
export function mergePromosSPO(stock, products) {
  if (!stock || !products || !stock.promos || !Array.isArray(products)) {
    return
  }

  return products.map((product) => {
    return mergePromo(stock, product);
  });
  
};

export function getPromoBadgesPLP(props, color, giftsWithStock ) {
  if (props.sizeOvered) {
    return getTargetAllPromos(props.sizeOvered, giftsWithStock);
  }
  
  return getValidPromos(props.product, color);
  
}

export function getPromoAgrupationPLP(props, color ) {
  if (props.sizeOvered) {
    return props.sizeOvered.principal_agrupation;
  }
  
  return getValidPromosAgrupation(props.product, color);
  
}

const mergePromo = (stock, product) => {
  if (!stock || !product || !stock.promos || !Array.isArray(product.promotional_actions) || !product.promotional_actions.length) {
    return
  }
  
  // promos
  if (stock.promos[product.id]) {
    stock.promos[product.id] = [...stock.promos[product.id], ...product.promotional_actions];
  } else {
    stock.promos[product.id] = product.promotional_actions;
  }
  stock.promos[product.id] = sortByPriority(stock.promos[product.id]);

  // gifs
  const principalGifSPO = getPrincipalGifPromoSPO(product.promotional_actions);
  if ( principalGifSPO) {
    stock.principalGifs[product.id] = principalGifSPO;
  }

  const principalAgrupation = mapPrincipalAgrupation(product.promotional_actions);
  if (principalAgrupation) {
    product.principal_agrupation = principalAgrupation;
  }
  
};

const mapPrincipalAgrupation = (promos) => {
  if (!Array.isArray(promos) || !promos.length) {
    return;
  }
  const promoWithMakeUpOrderedByPriority = promos
    .filter(
      (promo) => 
        promo.make_up && 
        Object.keys(promo.make_up).length &&
        STOCK_PROMOTION_TYPES_AGRUPATION.includes(promo.type)
    )
    .sort((a, b) => {
      return a.priority - b.priority;
    });
  const promoWithAgrupation = promoWithMakeUpOrderedByPriority.find((promo) => {
    return promo.make_up.promo_text_list_agrupation || promo.make_up.promo_text_detail_agrupation || promo.make_up.promo_text_agrupation;
  });

  if (!promoWithAgrupation) {
    return;
  }
  
  const { identifier, isSPO, make_up, priority, target_references } = promoWithAgrupation;

  return [{
    description: make_up.promo_text_detail_agrupation,
    endDate: make_up.end_date_time,
    gif: make_up.promo_gif_agrupation,
    id: identifier,
    isSPO: isSPO,
    priority: priority,
    startDate: make_up.start_date_time,
    target_references:  target_references,
    text: make_up.promo_text_agrupation,
    title: make_up.promo_text_list_agrupation,
    urlPromo: make_up.promo_url_agrupation,
  }];
  
}

const getPrincipalGifPromoSPO = (promotionalActions) => {
  if (!Array.isArray(promotionalActions)) {
    return;
  }
  const promoWithGif = promotionalActions
    .sort((a, b) => {
      return a.priority - b.priority;
    })
    .find((promo) => {
      return promo.gifs_free_text;
    });
  if (!promoWithGif) {
    return;
  }
  const isImage = promoWithGif.gifs_free_text.match(/\.(png|jpg|gif|svg|woff|woff2|eot|ttf)$/);
  if (!isImage) {
    return ;
  }
  return {
    key: 'custom',
    description: '',
    type: 'url_img',
    url: promoWithGif.gifs_free_text, // src de la imagen
    promo_id: promoWithGif.id,
    is_product_promo: Boolean(promoWithGif.products),
    promo_url_badge_image_pdp: promoWithGif.promo_url_badge_image_pdp,
    promo_url_badge_image_plp: promoWithGif.promo_url_badge_image_plp,
    isSPO: promoWithGif.isSPO,
    promo_url_badge_image_pdp_windows_type: promoWithGif.make_uppromo_url_badge_image_pdp_windows_type,
    promo_url_badge_image_plp_windows_type: promoWithGif.make_uppromo_url_badge_image_plp_windows_type,
  };
};

const sortByPriority = (promos) => {
  return promos.sort((pPromo, nPromo) => pPromo.priority - nPromo.priority);
};

const colorHasBadgeRange = (variants) => {
  if (!Array.isArray(variants) || !variants.length) {
    return false;
  }

  const uniqueBadges = new Set();

  for (const variant of variants) {
    if (stockUtils.hasAvailableStatus(variant.status)) {
      const currentVariantBadge = variant.sku_promo_badge
      uniqueBadges.add(currentVariantBadge?.id);
    }
  }

  return uniqueBadges.size > 1;
};

const colorHasGifRange = (variants) => {
  if (!Array.isArray(variants) || !variants.length) {
    return false;
  }

  const uniqueBadges = new Set();

  for (const variant of variants) {
    if (stockUtils.hasAvailableStatus(variant.status)) {
      const currentVariantGif= variant.principal_gif
      uniqueBadges.add(currentVariantGif?.id);
    }
  }

  return uniqueBadges.size > 1;
};

function getTargetAllPromos(sizeOvered, giftsWithStock) {
  const targetAllPromos = sizeOvered?.all_promos;
  if (!Array.isArray(targetAllPromos)) {
    return [];
  }
  return targetAllPromos.filter((promo) => {
    if (promo.target_references?.length) {
      return promo.target_references.some((reference) => giftsWithStock.value.includes(reference));
    } else {
      return true;
    }
  }) ?? [];
}

function getValidPromos(product, color) {
  const validPromos = product.principal_promo?.filter(promo => promo.title_list);
  if (Array.isArray(validPromos) && validPromos.length && !validPromos[0]?.isSPO) {
    return product.principal_promo;
  }
  return color.value?.principalPromosColorVariant;
}

function getValidPromosAgrupation(product, color) {
  return product.principal_agrupation || color.value?.principalPromoAgrupationColorVariant;
}

